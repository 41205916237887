import React, { useState } from "react"
import styled from "styled-components"
import { colorPrimary } from "../utilities/variables"
import { SecondaryBtn } from "./buttons"
import play from "../assets/images/icons/play-icon-white.svg"

const StyledFullWidthMedia = styled.div`
  position: relative;
  /* left: -20px; */
  max-height: 700px;
  width: 100vw;
  overflow: hidden;
  background: ${colorPrimary};
  margin: 70px 0;
  .large-image,
  video {
    width: 100%;
    object-fit: cover;
    opacity: 0.7;
    max-height: 500px;
  }
  button {
    position: absolute;
    margin: auto;
    top: 45%;
    left: 0;
    right: 0;
    z-index: 1;
  }
`

export const StyledMedia = ({ type, source }) => {
  const [played, setPlayed] = useState(false)
  return (
    <StyledFullWidthMedia>
      {type === "image" && (
        <>
          <img className="large-image" alt="" src={source} />
        </>
      )}
      {type === "video" && (
        <>
          {played ? (
            <></>
          ) : (
            <SecondaryBtn darkBg onClick={() => setPlayed(true)}>
              <span>
                <img src={play} alt="play" />
                Play Video
              </span>
            </SecondaryBtn>
          )}
          <video controls>
            <source src={source} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </>
      )}
    </StyledFullWidthMedia>
  )
}
