import styled from "styled-components"
import { BtnBoxShadow, colorSuccess, colorWhite } from "../utilities/variables"

export const BulletPointContainer = styled.div`
  display: grid;
  max-width: 250px;
  width: 100%;
  p {
    opacity: 0.8;
  }
  h3 {
    margin: 20px 0;
    padding: 0;
  }
`

export const BulletPointNumber = styled.div`
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: ${colorSuccess};
  box-shadow: ${BtnBoxShadow};
  border-radius: 5px;
  text-align: center;
  color: ${colorWhite};
  font-weight: bold;
`
