import { createGlobalStyle } from "styled-components"
import SofiaPro from "../assets/fonts/sofiapro-regular.ttf"
import { colorLinks } from "../utilities/variables"

export const GlobalStyles = createGlobalStyle`
	@font-face {
		font-family: 'Sofia Pro';
		src: url(${SofiaPro}) format('opentype');
		font-weight: 300;
		font-style: normal;
    -webkit-font-smoothing: antialiased;
	}
  html, body {
    margin: 0;
    padding: 0;
  }
  *, *::after, *::before {
    box-sizing: border-box;
  }

  body {
    font-family: 'Sofia Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    height: 100vh;
    justify-content: center;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
  }

  #___gatsby{
    overflow-x: hidden;
  }

  button {
    font-family: 'Sofia Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    display: grid;
    line-height: 16px;
    /* grid-template-columns: max-content max-content; */
    align-items: center;
    justify-content: center;
    > span {
      display: grid;
      grid-template-columns: max-content max-content;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
  }

  a{
    color: ${colorLinks};
  }

  textarea,input  {
    font-family: 'Sofia Pro';
    font-size:16px
  }

  main {
    .text-layout__StyledTextLayout-jiaxHg:nth-child(odd) > .text-layout__StyledTextLayout-jiaxHg {
      margin-left: 20px;
    }

    .text-layout__StyledTextLayout-jiaxHg:nth-child(even),
    .cross:nth-child(even) {
      direction: rtl;
      text-align: end; 
      > * {
        direction: ltr;
        text-align: left;
      }
    }

    .text-layout__StyledTextLayout-jiaxHg:nth-child(even) > .text-layout__StyledTextLayout-jiaxHg,
    .cross:nth-child(even) {
      justify-self: end;
      text-align: end;
    }
  }

  h1, h2, h3{
    text-align: center;
  }
`
