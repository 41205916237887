import React, { createRef, useEffect } from "react"
import styled from "styled-components"
import lottie from "lottie-web"
import animation from "../assets/animations/footer/toot-web-footer-left.json"
import gradient from "../assets/animations/footer/toot-gradient-2.svg"
import { ContentText, HeaderText, SubContentText } from "../elements"
import googlePlay from "../assets/images/icons/google-play-badge.png"
import appStore from "../assets/images/icons/apple-store-badge.png"
import flag from "../assets/images/icons/uk.png"
import {
  colorPrimary,
  colorWhite,
  colorGreyInputDisabled,
} from "../utilities/variables"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

// Social Media Icons
import fbselected from "../assets/images/icons/social/FB_selected.svg"
import fbunselected from "../assets/images/icons/social/FB_unselected.svg"
import instaselected from "../assets/images/icons/social/Instagram_selected.svg"
import instaunselected from "../assets/images/icons/social/insta_unselected.svg"
import twitterselected from "../assets/images/icons/social/Twitter_Selected.svg"
import twitterunselected from "../assets/images/icons/social/twitter_unselected.svg"

const Footer = styled.footer`
  box-shadow: 0 0 10px 10px ${colorGreyInputDisabled};
  background: ${colorWhite};
  .animation-container {
    height: 420px;
    @media (min-width: 950px) {
      z-index: 0;
      position: absolute;
    }
  }
  .join {
    max-width: 800px;
    text-align: center;
    margin: 0 auto;
    display: grid;
    align-content: center;
    justify-items: center;
    padding: 0 35px;
    padding-bottom: 30px;
    position: relative;
    z-index: 2;
    @media (min-width: 950px) {
      height: 421px;
    }
    .gradient {
      display: none;
      @media (min-width: 950px) {
        display: block;
        position: absolute;
        z-index: -1;
      }
    }
    p {
      margin: 0;
    }
    .download-app {
      display: grid;
      grid-template-columns: max-content max-content;
      gap: 15px;
      margin-top: 30px;
    }
    @media (min-width: 1050) {
      padding: 0;
    }
    h2 {
      margin-bottom: 25px;
    }
  }
  .bottom {
    height: 280px;
    background: ${colorPrimary};
    display: grid;
    align-content: center;
    justify-content: center;
    text-align: center;
    h2,
    p {
      color: ${colorWhite};
    }
    h2 {
      margin-bottom: 0;
    }
    .logo {
      margin: 0 auto;
    }
    .mobile {
      display: grid;
      @media (min-width: 950px) {
        display: none !important;
      }
      &.social-icons {
        display: grid;
        grid-template-columns: repeat(3, max-content);
        gap: 10px;
        margin: 0 auto;
        .fb {
          width: 30px;
          height: 30px;
          background-image: url(${fbunselected});
          &:hover {
            background-image: url(${fbselected});
          }
        }
        .instagram {
          width: 30px;
          height: 30px;
          background-image: url(${instaunselected});
          &:hover {
            background-image: url(${instaselected});
          }
        }
        .twitter {
          width: 30px;
          height: 30px;
          background-image: url(${twitterunselected});
          &:hover {
            background-image: url(${twitterselected});
          }
        }
      }
    }
  }
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid ${colorWhite};
    background-color: ${colorPrimary};
    margin: 1em 0;
    padding: 0;
    margin: 0;
    opacity: 0.17;
  }
  .links {
    background: ${colorPrimary};
    display: grid;
    @media (min-width: 950px) {
      height: 65px;
    }
    > div {
      max-width: 1300px;
      width: 100%;
      padding: 0 5%;
      margin: 0 auto;
      display: grid;
      align-items: center;
      gap: 30px;
      @media (min-width: 950px) {
        grid-template-columns: max-content 1fr max-content;
      }
      p {
        margin: 0;
      }
      .language {
        display: none;
        grid-template-columns: max-content max-content;
        align-items: center;
        gap: 5px;
        @media (min-width: 950px) {
          display: grid;
        }
      }
    }
    .link-items {
      display: grid;
      gap: 30px;
      text-align: center;
      padding-top: 30px;
      padding-bottom: 30px;
      @media (min-width: 950px) {
        padding-top: 0;
        padding-bottom: 0;
        grid-template-columns: repeat(10, max-content);
        text-align: start;
      }
      a {
        text-decoration: none;
      }
    }
    .social-icons {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
      .fb {
        width: 30px;
        height: 30px;
        background-image: url(${fbunselected});
        &:hover {
          background-image: url(${fbselected});
        }
      }
      .instagram {
        background-image: url(${instaunselected});
        &:hover {
          background-image: url(${instaselected});
        }
      }
      .twitter {
        background-image: url(${twitterunselected});
        &:hover {
          background-image: url(${twitterselected});
        }
      }
    }
    .desktop {
      display: none;
      @media (min-width: 950px) {
        display: grid;
      }
    }
    .flag-mobile {
      display: grid;
      grid-template-columns: max-content max-content;
      gap: 10px;
      justify-content: center;
      align-items: center;
      @media (min-width: 950px) {
        display: none;
      }
    }
    .mobile {
      display: block;
      @media (min-width: 950px) {
        display: none;
      }
    }
  }
`

const StyledFooterText = styled(SubContentText)`
  color: ${colorWhite};
  ${props => props.fadedx2 && `opacity: 0.5px;`}
  @media(min-width: 950px) {
    font-size: 12px;
  }
`

export const StyledFooter = () => {
  let animationContainer = createRef()

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const data = useStaticQuery(graphql`
    query {
      allStrapiFooter {
        edges {
          node {
            id
            CTATitle
            CTADescription
            FooterTitle
            FooterDescription
            AppStoreLink
            GooglePlayLink
            Facebook
            Instagram
            Twitter
            Item {
              Link
              Title
              id
            }
            FooterLogo {
              publicURL
            }
          }
        }
      }
    }
  `)

  const content = data.allStrapiFooter.edges[0].node

  return (
    <Footer>
      <div className="animation-container" ref={animationContainer}></div>
      <div className="join">
        <img src={gradient} alt="gradient" className="gradient" />
        <HeaderText>{content.CTATitle}</HeaderText>
        <ContentText
          dangerouslySetInnerHTML={{ __html: content.CTADescription }}
        ></ContentText>
        <div className="download-app">
          <a href={content.AppStoreLink} target="_blank" rel="noreferrer">
            <img src={appStore} alt="App Store" className="app-store" />
          </a>
          <a href={content.GooglePlayLink} target="_blank" rel="noreferrer">
            <img src={googlePlay} alt="Google Play" />
          </a>
        </div>
      </div>
      <div className="bottom">
        <img src={content.FooterLogo.publicURL} alt="logo" className="logo" />
        <HeaderText>{content.FooterTitle}</HeaderText>
        <ContentText>{content.FooterDescription}</ContentText>
        <div className="social-icons mobile">
          <a
            href={content.Facebook}
            target="_blank"
            rel="noreferrer"
            className="fb"
          >
            {" "}
          </a>
          <a
            href={content.Instagram}
            target="_blank"
            rel="noreferrer"
            className="instagram"
          >
            {" "}
          </a>
          <a
            href={content.Twitter}
            target="_blank"
            rel="noreferrer"
            className="twitter"
          >
            {" "}
          </a>
        </div>
      </div>
      <hr />
      <div className="links">
        <div>
          <div className="language">
            <img src={flag} alt="flag" />
            <StyledFooterText faded>English</StyledFooterText>
          </div>
          <div className="link-items">
            <StyledFooterText className="desktop" fadedx2>
              © 2020 Toot.co
            </StyledFooterText>
            {content.Item.map(i => {
              return (
                <Link to={i.Link} key={i.id}>
                  <StyledFooterText faded>{i.Title}</StyledFooterText>
                </Link>
              )
            })}
            <StyledFooterText faded className="flag-mobile">
              <img src={flag} alt="flag" /> English
            </StyledFooterText>{" "}
            <StyledFooterText faded className="mobile">
              © 2020 Toot.co
            </StyledFooterText>
          </div>
          <div className="social-icons desktop">
            <a
              href={content.Facebook}
              target="_blank"
              rel="noreferrer"
              className="fb"
            >
              {" "}
            </a>
            <a
              href={content.Instagram}
              target="_blank"
              rel="noreferrer"
              className="instagram"
            >
              {" "}
            </a>
            <a
              href={content.Twitter}
              target="_blank"
              rel="noreferrer"
              className="twitter"
            >
              {" "}
            </a>
          </div>
        </div>
      </div>
    </Footer>
  )
}
