import React, { createRef } from "react"
import styled from "styled-components"
import x from "../assets/images/icons/x.svg"
import fileIcon from "../assets/images/icons/file.svg"
import { SmallContentText, SubContentText } from "./typography"

const StyledFileInput = styled.div`
  input {
    visibility: hidden;
    width: 1px;
    height: 1px;
  }
  img {
    cursor: pointer;
  }
  > div {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 13px;
    align-items: center;
    .btn {
      width: 185px;
    }
  }
  .border {
    background: #3e486805 0% 0% no-repeat padding-box;
    border: 1px solid #3e486826;
    border-radius: 4px;
    padding: 10px 15px;
    justify-content: center;
    margin: 0;
    display: flex;
    align-items: center;
    min-width: 185px;
    cursor: pointer;
    img {
      margin-right: 10px;
    }
  }
  .border.bold {
    font-weight: bold;
  }
`

export const FileUpload = ({ file, updateFile }) => {
  const input = createRef()

  const handleUpdateFile = e => {
    if (e.target.files[0] === "" || e.target.files[0] === undefined) {
      updateFile("")
    } else {
      updateFile(e.target.files[0])
    }
  }

  return (
    <StyledFileInput>
      <div>
        <label HTMLFor="file" for="file" className="btn">
          <SubContentText className="border bold">
            Attach Resume/CV
          </SubContentText>
        </label>
        <SmallContentText>(PDF, Doc, Pages, Word)</SmallContentText>
        <input
          id="file"
          type="file"
          placeholder="Attach Resume/CV"
          accept=".pdf,.doc,.docx,.pages"
          // value={file}
          ref={input}
          onChange={e => handleUpdateFile(e)}
        />
      </div>
      {file !== "" && file !== undefined && (
        <div>
          <SmallContentText className="border">
            <img src={fileIcon} alt="file" /> {file !== "" && file.name}
          </SmallContentText>
          <img src={x} alt="close" onClick={(...file) => updateFile("")} />
        </div>
      )}
    </StyledFileInput>
  )
}
