import styled from "styled-components"
import {
  colorPrimary,
  colorWhite,
  colorSuccess,
  colorGreenBtnHover,
  BtnBoxShadow,
  colorGreyInput,
  colorGreyInputDisabled,
  colorGreyInputHover,
} from "../utilities/variables"

// <PrimaryBtn darkBg> | <PrimaryBtn>
export const PrimaryBtn = styled.button`
  cursor: pointer;
  font-weight: bold;
  border: none;
  color: ${colorWhite};
  border-radius: 5px;
  height: 35px;
  max-width: 150px;
  width: 100%;
  font-size: 16px;
  background-color: ${colorSuccess};
  box-shadow: ${BtnBoxShadow};
  :hover {
    border: 1px solid ${colorGreenBtnHover};
  }
  :active {
    background-color: ${colorGreenBtnHover};
  }
  :disabled {
    border: 1px solid ${props => (props.darkBg ? colorWhite : colorGreyInput)};
    background-color: ${colorGreyInputDisabled};
    box-shadow: none;
    opacity: 0.5;
    color: ${props => (props.darkBg ? colorWhite : colorPrimary)};
  }
  img {
    /* width: 20px; */
    height: 20px;
    padding-right: 10px;
  }
  * {
    vertical-align: middle;
  }
`

// <SecondaryBtn darkBg> | <SecondaryBtn>
export const SecondaryBtn = styled.button`
  cursor: pointer;
  background: none;
  font-weight: bold;
  font-size: 16px;
  color: ${props => (props.darkBg ? colorWhite : colorPrimary)};
  border: 1px solid
    ${props => (props.darkBg ? colorWhite : colorGreyInputHover)};
  border-radius: 5px;
  height: 35px;
  max-width: 150px;
  width: 100%;
  :hover {
    border-color: ${props => (props.darkBg ? colorWhite : colorPrimary)};
  }
  :active {
    border-color: ${props => (props.darkBg ? colorWhite : colorPrimary)};
    background-color: ${colorGreyInputDisabled};
  }
  :disabled {
    border: 1px solid ${props => (props.darkBg ? colorWhite : colorGreyInput)};
    background-color: ${colorGreyInputDisabled};
    color: ${props => (props.darkBg ? colorWhite : colorPrimary)};
    opacity: 0.5;
  }
  img {
    /* width: 20px; */
    height: 20px;
    padding-right: 10px;
  }
  * {
    vertical-align: middle;
  }
`
