import styled from "styled-components"
import {
  colorPrimary,
  colorGreyInput,
  colorGreyInputHover,
  colorGreyInputDisabled,
} from "../utilities/variables"
import chevron from "../assets/images/icons/chevron.svg"
import chevronUp from "../assets/images/icons/chevron-up.svg"
import search from "../assets/images/icons/search-icon.svg"

// <Input type="text" placeholder="Placeholder" />
export const Input = styled.input`
  border: 1px solid ${colorGreyInput};
  border-radius: 4px;
  padding: 0px 12px;
  font-size: 16px;
  height: 40px;
  max-width: 450p;
  width: 100%;
  color: ${colorPrimary};
  outline: none;
  ::placeholder {
    opacity: 0.5;
    color: ${colorPrimary};
  }
  :hover {
    border-color: ${colorGreyInputHover};
  }
  :focus {
    color: ${colorPrimary};
    border-color: ${colorPrimary};
  }
  :disabled {
    border-color: ${colorGreyInput};
    background: ${colorGreyInputDisabled};
    color: colorPrimary;
  }
`

// <DropDown required>
// <option value="" disabled selected hidden>Please choose...</option>
export const DropDown = styled.select`
  border: 1px solid ${colorGreyInput};
  border-radius: 4px;
  padding: 0 12px;
  font-size: 16px;
  height: 40px;
  max-width: 450px;
  width: 100%;
  outline: none;
  color: ${colorPrimary};

  background: no-repeat url(${chevronUp}) center right 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  :invalid {
    opacity: 0.5;
    color: ${colorPrimary};
  }
  :hover {
    border-color: ${colorGreyInputHover};
  }
  :focus {
    background-image: url(${chevron});
    color: ${colorPrimary};
    border-color: ${colorPrimary};
  }
  :disabled {
    border-color: ${colorGreyInput};
    background: ${colorGreyInputDisabled};
    color: colorPrimary;
  }
`
// <TextArea>
export const TextArea = styled.textarea`
  border: 1px solid ${colorGreyInput};
  border-radius: 4px;
  max-width: 450px;
  width: 100%;
  padding: 13px 12px;
  font-size: 16px;
  color: ${colorPrimary};
  outline: none;
  ::placeholder {
    opacity: 0.5;
    color: ${colorPrimary};
  }
  :focus {
    border-color: ${colorGreyInputHover};
  }
  :hover {
    border-color: ${colorGreyInputHover};
  }
`

// <SearchBar>
export const SearchBar = styled.input`
  border: 1px solid ${colorGreyInput};
  border-radius: 4px;
  max-width: calc(450px - 24px - 2px);
  width: 100%;
  padding: 0 12px;
  height: 40px;
  font-size: 16px;
  color: ${colorPrimary};
  outline: none;
  background: no-repeat url(${search}) center right 15px;
  ::placeholder {
    opacity: 0.5;
    color: ${colorPrimary};
  }
  :focus {
    border-color: ${colorGreyInputHover};
  }
  :hover {
    border-color: ${colorGreyInputHover};
  }
`

// <InputLabel>
export const InputLabel = styled.label`
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #3e4868;
  text-align: start;
  padding-left: 12px;
`

// <InputContainer>
export const InputContainer = styled.div`
  max-width: 450px;
  width: 100%;
  text-align: start;
`
