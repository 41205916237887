import React from "react"
import styled from "styled-components"

import { colorPrimary } from "../utilities/variables"

const BackNextBtn = styled.a`
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  color: ${colorPrimary};
  opacity: 1;
  :hover {
    text-decoration: underline;
  }
`

const PaginationContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  gap: 25px;
  margin: 0 auto;
  max-width: fit-content;
`

const StyledPage = styled.a`
  text-decoration: none;
  color: ${props => (props.active ? colorPrimary : `gray`)};
  /* font-weight: ${props => (props.active ? `bold` : `normal`)}; */
  font-weight: normal;
`

const StyledPageContainer = styled.span`
  display: grid;
  grid-template-columns: repeat(${props => props.pages}, auto);
  gap: 10px;
`

export const StyledPagination = ({ currentPage, pages, changePage }) => {
  const GetPages = () => {
    let links = []
    for (let count = 1; count <= pages; count++) {
      count === currentPage
        ? links.push(
            <StyledPage href="#" active>
              {count}
            </StyledPage>
          )
        : links.push(
            <StyledPage href="#" onClick={() => change(count)}>
              {count}
            </StyledPage>
          )
    }
    return links
  }

  const change = page => {
    changePage(page)
  }

  return (
    <PaginationContainer>
      {currentPage !== 1 && (
        <BackNextBtn href="#" onClick={() => change(currentPage - 1)}>
          Back
        </BackNextBtn>
      )}
      <StyledPageContainer pages={pages}>{GetPages()}</StyledPageContainer>
      {currentPage !== pages && (
        <BackNextBtn href="#" onClick={() => change((currentPage += 1))}>
          Next
        </BackNextBtn>
      )}
    </PaginationContainer>
  )
}
