export const colorPrimary = "#3E4868"
export const colorSuccess = "#36D6A5"
export const colorError = "#FA45B4"
export const colorLinks = "#3EBCFF"
export const colorWhite = "#FFFFFF"
export const colorGrey = "#FCFCFC"

// Greys:
export const colorGreyInput = "hsla(226, 25%, 33%, 26%)"
export const colorGreyInputHover = "hsla(226, 25%, 33%, 80%)"
export const colorGreyInputDisabled = "hsla(226, 25%, 33%, 5%)"
export const colorGreyBtnActive = "hsla(226, 25%, 33%, 19%)"
export const colorGreenBtnHover = "#30C497"

// Buttons box shadow
export const BtnBoxShadow = "0px 5px 15px hsla(162, 66%, 53%, 80%)"
export const CellBoxShadow = "0px 4px 6px hsla(226, 25%, 33%, 10%)"

export const imageHover = `
	.img-container {
		overflow: hidden;
		height: 150px;
  	}
	img {
		height: 150px;
		width: 100%;
		object-fit: cover;
		transition: transform 0.2s;
		&:hover {
		transform: scale(1.3);
		}
  	}`
